<template>
  <TModal
    title="Add category"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
    :creating="category_id ? false : true"
  >
    <template #actions>
      <TButton
        content="Create"
        :options="{ disabled: loading || !category_id }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <SSelectProductCategory
      label="Category"
      :prepend="[]"
      :value.sync="category_id"
      @update:value="category_id = $event"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      category_id: "",
      loading: false,
    };
  },
  methods: {
    add() {
      this.loading = true;
      this.$store
        .dispatch("product.products.detail.attach-category", {
          "category_ids[]": this.category_id,
        })
        .finally(() => {
          this.category_id = "";
          this.loading = false;
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
