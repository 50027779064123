<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap align-items-center">
      <TMessage content="Supplier list" bold />
      <TSpinner :loading="fetching"/>
      <div class="ml-auto">
        <TButton
          content="Add supplier"
          icon="cil-plus"
          @click="showAddSupplier = true"
          variant="outline"
        />
      </div>
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px">
      <CCard
        borderColor="primary"
        v-for="supplier in product.suppliers"
        :key="supplier.id"
      >
        <CCardHeader class="p-2 d-flex flex-wrap align-items-center">
          <TMessage :content="supplier.name" bold noTranslate
            ><template #prefix>{{ supplier.id }} - </template>
          </TMessage>
          <div class="ml-auto">
            <TButtonRemove
              @click="
                $store.dispatch(
                  'product.products.detail.delete-supplier',
                  supplier.id
                )
              "
            />
          </div>
        </CCardHeader>
        <CCardBody class="p-2">
          <TTableAsForm :data="supplier" :fields="detail" :splitLeft="5">
            <template #address="{ value }">
              <TMessage :content="value" noTranslate />
            </template>
            <template #email="{ value }">
              <TMessage :content="value" noTranslate />
            </template>
            <template #note="{ value }">
              <TMessage :content="value" noTranslate />
            </template>
            <template #price="{ data }">
              <TMessageMoney
                :amount="lodash.toNumber(data.pivot.price)"
                editable
                @change="
                  $store.dispatch('product.products.detail.update-supplier', {
                    supplier_id: data.id,
                    price: $event,
                  })
                "
                currency="JPY"
              />
            </template>
            <template #quantity_items="{ data }">
              <TMessageNumber
                :value="data.pivot.quantity_items"
                editable
                @change="
                  $store.dispatch('product.products.detail.update-supplier', {
                    supplier_id: data.id,
                    quantity_items: $event,
                  })
                "
              />
            </template>
          </TTableAsForm>
        </CCardBody>
      </CCard>
      <TMessageNoData v-if="product.suppliers.length === 0" />
    </CCardBody>
    <AddSupplier :show.sync="showAddSupplier" />
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import AddSupplier from "./AddSupplier.vue";
export default {
  components: { AddSupplier },
  data() {
    return {
      detail: [
        { key: "address", label: "Address" },
        { key: "email", label: "Email" },
        { key: "note", label: "Note" },
        { key: "price", label: "Price" },
        { key: "quantity_items", label: "Items in box" },
      ],
      showAddSupplier: false,
    };
  },
  computed: {
    ...mapGetters({
      product: "product.products.detail",
      fetching: "product.products.detail.fetching",
    }),
  },
};
</script>
