<template>
  <TModal
    title="Change product image"
    :show="show"
    @update:show="$emit('update:show', $event)"
    :key="key"
  >
    <template #actions>
      <TButtonSave
        variant="outline"
        @click="update"
        :options="{ disabled: disabled }"
      />
    </template>
    <CImg
      style="height: 250px; object-fit: contain"
      thumbnail
      fluidGrow
      :src="image ? image : image_url"
      placeholderColor
      class="mb-3 p-0"
    />
    <TInputFile
      :value.sync="file"
      :accept="'image/*'"
      @update:value="onFileChange($event)"
      class="w-100"
      :key="fileKey"
      :multiple="false"
    />
    <TInputText
      :value.sync="image_url"
      @update:value="imgUrlChange"
      class="w-100 mt-3"
      placeholder="Enter image url"
      :inputOptions="{ prepend: 'URL' }"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      image_url: "",
      image: "",
      file: [],
      key: "key",
      disabled: false,
      fileKey: "file-key",
    };
  },
  watch: {
    primaryImage: {
      deep: true,
      handler(img) {
        this.image = img.url;
      },
    },
  },
  computed: {
    primaryImage() {
      return this.images.find((x) => x.primary) || {};
    },
  },
  methods: {
    onFileChange(file) {
      if (this.file.length == 1) {
        this.image = URL.createObjectURL(file[0].file);
      } else {
        this.image = "";
      }
      this.image_url = "";
    },
    imgUrlChange(e) {
      this.file = [];
      this.image = "";
      this.image_url = e;
      this.fileKey = this.lodash.resetKey("file-key");
    },
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.key = this.lodash.resetKey("key");
    },
    update() {
      const primaryImageId = this.primaryImage.id;
      this.$store
        .dispatch(
          "product.products.detail.attach-image",
          this.lodash.pickBy({
            "images[0][file]": this.file.length ? this.file[0].file : undefined,
            "images[0][url]": this.image_url ? this.image_url : undefined,
            "images[0][primary]": 1,
          })
        )
        .then(() => {
          this.reset();
          this.$store.dispatch("product.products.detail.detach-image", {
            "product_image_ids[]": primaryImageId,
          });
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
