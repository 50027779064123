var render = function render(){var _vm=this,_c=_vm._self._c;return _c('CCard',[_c('CCardHeader',{staticClass:"d-flex flex-wrap align-items-center"},[_c('TMessage',{attrs:{"content":"Supplier list","bold":""}}),_c('TSpinner',{attrs:{"loading":_vm.fetching}}),_c('div',{staticClass:"ml-auto"},[_c('TButton',{attrs:{"content":"Add supplier","icon":"cil-plus","variant":"outline"},on:{"click":function($event){_vm.showAddSupplier = true}}})],1)],1),_c('CCardBody',{staticClass:"w-100 mx-auto",staticStyle:{"max-width":"800px"}},[_vm._l((_vm.product.suppliers),function(supplier){return _c('CCard',{key:supplier.id,attrs:{"borderColor":"primary"}},[_c('CCardHeader',{staticClass:"p-2 d-flex flex-wrap align-items-center"},[_c('TMessage',{attrs:{"content":supplier.name,"bold":"","noTranslate":""},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._v(_vm._s(supplier.id)+" - ")]},proxy:true}],null,true)}),_c('div',{staticClass:"ml-auto"},[_c('TButtonRemove',{on:{"click":function($event){return _vm.$store.dispatch(
                'product.products.detail.delete-supplier',
                supplier.id
              )}}})],1)],1),_c('CCardBody',{staticClass:"p-2"},[_c('TTableAsForm',{attrs:{"data":supplier,"fields":_vm.detail,"splitLeft":5},scopedSlots:_vm._u([{key:"address",fn:function({ value }){return [_c('TMessage',{attrs:{"content":value,"noTranslate":""}})]}},{key:"email",fn:function({ value }){return [_c('TMessage',{attrs:{"content":value,"noTranslate":""}})]}},{key:"note",fn:function({ value }){return [_c('TMessage',{attrs:{"content":value,"noTranslate":""}})]}},{key:"price",fn:function({ data }){return [_c('TMessageMoney',{attrs:{"amount":_vm.lodash.toNumber(data.pivot.price),"editable":"","currency":"JPY"},on:{"change":function($event){return _vm.$store.dispatch('product.products.detail.update-supplier', {
                  supplier_id: data.id,
                  price: $event,
                })}}})]}},{key:"quantity_items",fn:function({ data }){return [_c('TMessageNumber',{attrs:{"value":data.pivot.quantity_items,"editable":""},on:{"change":function($event){return _vm.$store.dispatch('product.products.detail.update-supplier', {
                  supplier_id: data.id,
                  quantity_items: $event,
                })}}})]}}],null,true)})],1)],1)}),(_vm.product.suppliers.length === 0)?_c('TMessageNoData'):_vm._e()],2),_c('AddSupplier',{attrs:{"show":_vm.showAddSupplier},on:{"update:show":function($event){_vm.showAddSupplier=$event}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }