<template>
  <TTabs :tabs="tabs" :activeTab="$route.query.tab" @change-tab="changeTab">
    <template #detail> <Product /> </template>
    <template #supplier> <Supplier /> </template>
    <template #review> <ProductReview /> </template>
    <template #supplier-badge>
      <CBadge v-if="product.suppliers" color="primary">{{
        product.suppliers.length
      }}</CBadge>
    </template>
  </TTabs>
</template>
<script>
import Supplier from "./components/Supplier.vue";
import Product from "./components/Product.vue";
import ProductReview from "./components/ProductReview.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Product,
    Supplier,
    ProductReview,
  },
  data() {
    return {
      tabs: [
        { key: "detail", name: "Detail", icon: "cis-chart-table" },
        { key: "review", name: "Review", icon: "cis-comment-bubble" },
        { key: "supplier", name: "Supplier", icon: "cis-store" },
      ],
    };
  },
  created() {
    if (this.id) {
      this.$store.dispatch("product.products.detail.fetch", this.id);
    }
  },
  computed: {
    ...mapGetters({
      product: "product.products.detail",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    changeTab(tab) {
      this.$router.push({ query: { tab: tab } });
    },
  },
};
</script>
