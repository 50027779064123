<template>
  <TModal
    title="Add tag"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="add"
  >
    <template #actions>
      <TButton
        content="Add"
        :options="{ disabled: loading || !tagsSelected.length }"
        icon="cil-plus"
        @click="add"
      />
    </template>
    <TInputCheckbox
      v-for="tag in list"
      :key="tag.value"
      :checked="tagsSelected.includes(tag.value)"
      @change="addTag(tag.value)"
      :label="tag.name"
      class="mb-1"
      noTranslate
    />
  </TModal>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tagsSelected: [],
      loading: false,
    };
  },
  computed: {
    ...mapGetters({
      tags: "product.product_tags.list",
    }),
    list() {
      return this.tags.map((x) => {
        return {
          value: x.id,
          name: x.name,
        };
      });
    },
  },
  created() {
    this.$store.dispatch("product.product_tags.fetch.if-first-time");
  },
  methods: {
    addTag(tag) {
      const existIndex = this.tagsSelected.findIndex((x) => x == tag);
      if (existIndex < 0) this.tagsSelected.push(tag);
      else this.tagsSelected.splice(existIndex, 1);
    },
    add() {
      this.loading = true;
      let data = {};
      this.tagsSelected.forEach((tag, index) => {
        // Remove keys
        const item = { [`tag_ids[${index}]`]: tag };
        data = { ...item, ...data };
      });
      this.$store
        .dispatch("product.products.detail.attach-tag", data)
        .finally(() => {
          this.tagsSelected = [];
          this.loading = false;
          this.$emit("update:show", false);
        });
    },
  },
};
</script>
