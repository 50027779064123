<template>
  <TModal
    title="Add supplier"
    :show="show"
    @update:show="$emit('update:show', $event)"
    @click-create="create()"
    :creating="disabled"
    :key="key"
  >
    <SSelectSupplier
      label="Supplier"
      @update:value="data.supplier_id = $event"
      class="mb-3"
    />
    <TInputNumber
      label="Items in box"
      @update:value="data.quantity_items = $event"
      class="mb-3"
      :value="data.quantity_items"
    />
    <TInputMoney
      label="Price"
      @update:value="data.price = $event"
      class="mb-3"
      :value="data.price"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      data: {},
      key: "origin",
      disabled: false
    };
  },
  methods: {
    create() {
      this.disabled = true
      this.$store
        .dispatch("product.products.detail.add-supplier", this.data)
        .then(() => {
          this.data = {};
          this.key = "" + this.lodash.random(10000, 100000);
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.disabled = false
        })
    },
  },
};
</script>
