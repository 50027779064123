<template>
  <CCard>
    <CCardHeader class="d-flex flex-wrap align-items-center">
      <TMessage content="Review" bold />
      <div class="d-flex ml-2 align-items-center">
        <TMessageNumber :value="product.star" />
        <CIcon name="cil-star" class="ml-1" />
      </div>
      <TSpinner :loading="loading" />
    </CCardHeader>
    <CCardBody class="w-100 mx-auto" style="max-width: 800px">
      <div v-for="review in product.reviews" :key="review.id" class="d-flex">
        <CCard class="w-100 ml-2">
          <CCardHeader class="d-flex align-items-center py-2" color="light">
            <TMessage
              :content="
                review.reviewer ? review.reviewer.name : review.reviewer_id
              "
              noTranslate
              bold
              color="dark"
            />
            <div class="ml-2 d-flex my-auto">
              <CIcon
                v-for="(star, index) in review.star"
                :key="index"
                name="cil-star"
                class="text-danger"
              />
            </div>
            <TMessageDateTime
              :content="review.created_at"
              dateOnly
              class="ml-auto"
            />
          </CCardHeader>
          <CCardBody class="py-2">
            <TMessageText
              editable
              @change="updateSupportContent(review.id, 'comment', $event)"
              :value="review.comment"
            />
          </CCardBody>
          <div v-show="paginate.last > 1" class="mt-2">
            <TPagination store="helper.support_contents" />
          </div>
        </CCard>
      </div>
    </CCardBody>
    <CCardFooter>
      <div class="d-flex">
        <div class="d-flex mt-1" style="min-width: 40px">
          <CIcon name="cisSpeechNotes" size="lg" class="mx-auto" />
        </div>
        <div class="w-100 ml-2">
          <TInputRadio
            label="Star rating"
            :list="star_rating"
            :value.sync="star"
            custom
            class="h-auto"
          />
          <TInputTextarea
            class="w-100"
            :value.sync="comment"
            placeholder="Review"
            style="height: 70px"
          />
          <TButton
            @click="create"
            :options="{
              disabled: loading,
            }"
            content="Send"
            size="lg"
            class="px-5 mt-3"
          />
        </div>
      </div>
    </CCardFooter>
    <AddSupplier :show.sync="showAddSupplier" />
  </CCard>
</template>

<script>
import { mapGetters } from "vuex";
import AddSupplier from "./AddSupplier.vue";
export default {
  components: { AddSupplier },
  data() {
    return {
      detail: [
        { key: "address", label: "Address" },
        { key: "email", label: "Email" },
        { key: "note", label: "Note" },
        { key: "price", label: "Price" },
        { key: "quantity_items", label: "Items in box" },
      ],
      showAddSupplier: false,
      comment: "",
      star: "5",
      star_rating: [
        { value: "1", label: "1" },
        { value: "2", label: "2" },
        { value: "3", label: "3" },
        { value: "4", label: "4" },
        { value: "5", label: "5" },
      ],
    };
  },
  computed: {
    ...mapGetters({
      product: "product.products.detail",
      reviews: "product.product_reviews.list",
      loading: "product.product_reviews.loading",
      paginate: "product.product_reviews.paginate",
    }),
    id() {
      return this.$route.params.id;
    },
  },
  methods: {
    fetchProduct() {
      this.$store.dispatch("product.products.detail.fetch", this.id);
    },
    fetchReviews() {
      this.$store.dispatch("product.product_reviews.apply-query", {
        "filter[product_id]": this.id,
      });
    },
    updateSupportContent(id, field, value) {
      this.$store
        .dispatch("product.product_reviews.update", {
          id,
          attributes: {
            [field]: value,
          },
        })
        .then(() => {
          this.fetchProduct();
        });
    },
    create() {
      let data = {
        product_id: this.id,
        comment: this.comment,
        star: this.star,
      };
      this.$store
        .dispatch(
          "product.product_reviews.create",
          this.lodash.pickBy(data, this.lodash.identity)
        )
        .then(() => {
          this.comment = "";
          this.star = "5";
          // this.fetchReviews();
          this.fetchProduct();
        });
    },
  },
};
</script>
