<template>
  <TModal
    title="Album edit"
    :show="show"
    @update:show="$emit('update:show', $event)"
    :key="key"
    size="lg"
  >
    <template #actions>
      <TButtonSave
        variant="outline"
        @click="onAttachImg"
        :options="{ disabled: disabled }"
      />
    </template>
    <div class="d-inline-flex flex-wrap">
      <TImg
        v-for="image in albumCurrent"
        :key="image.id"
        class="p-0 m-1"
        style="height: 150px; width: 150px; object-fit: contain"
        showFull
        :src="image.url ? image.url : ''"
        @click-delete="onDeleteImg(image.id)"
        deletable
      />
    </div>
    <TInputFile
      :value.sync="file"
      :accept="'image/*'"
      class="w-100 mt-2"
      :key="fileKey"
      :multiple="true"
    />
    <TInputText
      :value.sync="image_url"
      class="w-100 mt-3"
      placeholder="Enter image url"
      :inputOptions="{ prepend: 'URL' }"
    />
  </TModal>
</template>

<script>
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    productId: {
      type: String,
      required: true,
    },
    images: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      image_url: "",
      file: [],
      key: "key",
      disabled: false,
      fileKey: "file-key",
    };
  },
  computed: {
    albumCurrent() {
      return this.images.filter((x) => !x.primary) || [];
    },
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.apply(this));
      this.key = this.lodash.resetKey("key");
    },
    onDeleteImg(id) {
      this.$store.dispatch("product.products.detail.detach-image", {
        "product_image_ids[]": id,
      });
    },
    onAttachImg() {
      let data = {};
      this.file.forEach((x, i) => {
        const item = { [`images[${i}][file]`]: x.file };
        data = { ...data, ...item };
      });
      this.$store
        .dispatch(
          "product.products.detail.attach-image",
          this.lodash.pickBy({
            ...data,
            [`images[${this.file.length}][url]`]: this.image_url
              ? this.image_url
              : undefined,
          })
        )
        .then(() => {
          this.reset();
          this.$emit("update:show", false);
        })
        .finally(() => {
          this.disabled = false;
        });
    },
  },
};
</script>
