<template>
  <CCard>
    <CCardHeader class="d-flex align-items-center">
      <TMessage :content="id" bold noTranslate />
      <TSpinner :loading="loading" />
      <TButton
        content="Add category"
        variant="outline"
        icon="cil-plus"
        class="ml-auto"
        @click="showModalAddCategory = true"
      />
      <TButton
        content="Add tag"
        variant="outline"
        icon="cil-plus"
        class="ml-1"
        @click="showModalAddTag = true"
      />
      <AddProductCategory :show.sync="showModalAddCategory" />
      <AddProductTag :show.sync="showModalAddTag" />
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12" md="6" xl="4">
          <TMessage content="Product image" class="mb-4" uppercase>
            <template #suffix>:</template>
          </TMessage>
          <TImg
            class="p-0"
            style="height: 250px; width: 250px; object-fit: contain"
            showFull
            :src="product.image_url ? product.image_url : ''"
          />
          <div class="mt-2 scroll-x" style="width: 250px">
            <div class="d-inline-flex">
              <TImg
                v-for="image in imageList"
                :key="image.id"
                class="p-0"
                style="height: 90px; width: 90px; object-fit: contain"
                showFull
                :src="image.url ? image.url : ''"
              />
            </div>
          </div>
          <TButton
            content="Change product image"
            @click="primaryImgModal = true"
            class="d-block mt-2 px-3"
            icon="cis-images"
            variant="outline"
          />
          <TButton
            content="Album edit"
            @click="albumModal = true"
            class="d-block mt-2 mb-4 px-3"
            icon="cilScaleImage"
            variant="outline"
            color="info"
          />
        </CCol>

        <CCol lg="8">
          <CRow>
            <CCol sm="12" md="12" lg="12" xl="6">
              <TTableAsForm
                title="Details"
                :data="product"
                :fields="detail"
                :splitLeft="6"
                class="mb-4"
              >
                <template #hs_code="{ value }">
                  <TMessageText
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('hs_code', $event)"
                  />
                </template>
                <template #name="{ value }">
                  <TMessageText
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('name', $event)"
                  />
                </template>
                <template #brand="{ value }">
                  <TMessageText
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('brand', $event)"
                  />
                </template>
                <template #link_store="{ value }">
                  <TMessageText
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('link_store', $event)"
                  />
                </template>
                <template #ecommerce_channel_id="{ value }">
                  <SMessageProductEc
                    :id="value"
                    :editable="!loading"
                    @change="updateProduct('ecommerce_channel_id', $event)"
                  />
                </template>
                <template #manufacturing_year="{ value }">
                  <TMessageText
                    :value="value"
                    :editable="!loading"
                    dateOnly
                    @change="updateProduct('manufacturing_year', $event)"
                  />
                </template>
                <template #code="{ value }">
                  <TMessageText
                    :value="value"
                    :editable="!loading"
                    dateOnly
                    @change="updateProduct('code', $event)"
                  />
                </template>
                <template #price="{ value }">
                  <TMessageMoney
                    :amount="value"
                    :currency="product.currency_id || 'JPY'"
                  />
                </template>
                <template #initial_price="{ value }">
                  <TMessageMoney
                    :amount="value"
                    :editable="!loading"
                    @change="updateProduct('initial_price', $event)"
                    :currency="product.currency_id || 'JPY'"
                  />
                </template>
                <template #discount="{ value }">
                  <TMessagePercent
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('discount', $event)"
                  />
                </template>
                <template #origin_id="{ value }">
                  <SMessageProductOrigin
                    :originId="value"
                    :editable="!loading"
                    @change="updateProduct('origin_id', $event)"
                  />
                </template>
                <template #tax_percent="{ value }">
                  <TMessagePercent
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('tax_percent', $event)"
                  />
                </template>
                <template #ingredients="{ value }">
                  <TMessageTextarea
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('ingredients', $event)"
                  />
                </template>
                <template #updated_at="{ value }">
                  <TMessageDateTime :content="value" small />
                </template>
              </TTableAsForm>
            </CCol>
            <CCol sm="12" md="12" lg="12" xl="6">
              <TTableAsForm
                title="Product box specifications"
                :data="product"
                :fields="specification"
                :splitLeft="6"
                class="mb-4"
              >
                <template #weight="{ value }">
                  <TMessageNumber
                    :value="value"
                    suffix="kg"
                    :editable="!loading"
                    @change="updateProduct('weight', $event)"
                  />
                </template>
                <template #height="{ value }">
                  <TMessageNumber
                    :value="value"
                    suffix="cm"
                    :editable="!loading"
                    @change="updateProduct('height', $event)"
                  />
                </template>
                <template #width="{ value }">
                  <TMessageNumber
                    :value="value"
                    suffix="cm"
                    :editable="!loading"
                    @change="updateProduct('width', $event)"
                  />
                </template>
                <template #length="{ value }">
                  <TMessageNumber
                    :value="value"
                    suffix="cm"
                    :editable="!loading"
                    @change="updateProduct('length', $event)"
                  />
                </template>
                <template #quantity_items="{ value }">
                  <TMessageNumber
                    :value="value"
                    :editable="!loading"
                    @change="updateProduct('quantity_items', $event)"
                  />
                </template>
              </TTableAsForm>
            </CCol>
            <CCol sm="12" md="12" lg="12" xl="6">
              <TTableAsForm
                title="Embargo"
                :data="product"
                :fields="embargo"
                :splitLeft="6"
                class="mb-4"
              >
                <template #special="{ value }">
                  <TInputCheckbox
                    :checked="value"
                    :disabled="loading"
                    @change="updateProduct('special', $event)"
                  />
                </template>
                <template #private_license="{ value }">
                  <TInputCheckbox
                    :checked="value"
                    :disabled="loading"
                    @change="updateProduct('private_license', $event)"
                  />
                </template>
                <template #banned_export="{ value }">
                  <TInputCheckbox
                    :checked="value"
                    :disabled="loading"
                    @change="updateProduct('banned_export', $event)"
                  />
                </template>
                <template #banned_sea="{ value }">
                  <TInputCheckbox
                    :checked="value"
                    :disabled="loading"
                    @change="updateProduct('banned_sea', $event)"
                  />
                </template>
                <template #banned_air="{ value }">
                  <TInputCheckbox
                    :checked="value"
                    :disabled="loading"
                    @change="updateProduct('banned_air', $event)"
                  />
                </template>
              </TTableAsForm>
            </CCol>
            <CCol sm="12" md="12" lg="12" xl="6">
              <TMessage content="Category" class="mb-2" uppercase>
                <template #suffix>:</template>
              </TMessage>
              <div
                v-if="product.categories && product.categories.length"
                class="d-flex flex-wrap"
              >
                <SMessageProductCategory
                  v-for="category in product.categories"
                  :key="category.id"
                  class="mr-1 mb-1"
                  :category="category"
                  :removable="!loading"
                />
              </div>
              <TMessageNotFound v-else />
              <TMessage content="Tag" class="mb-2 mt-3" uppercase>
                <template #suffix>:</template>
              </TMessage>
              <div
                v-if="product.tags && product.tags.length"
                class="d-flex flex-wrap"
              >
                <SMessageProductTag
                  v-for="tag in product.tags"
                  :key="tag.id"
                  class="mr-1 mb-1"
                  :tag="tag"
                  :removable="!loading"
                />
              </div>
              <TMessageNotFound v-else />
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CCardBody>
    <CRow class="mx-2 my-3">
      <CCol col="12">
        <div class="d-flex">
          <TMessage content="Description" class="mb-3" uppercase>
            <template #suffix>:</template>
          </TMessage>
          <div class="ml-auto my-auto">
            <TButtonEdit
              v-if="!isDescriptionChange"
              variant="outline"
              @click="isDescriptionChange = !isDescriptionChange"
            />
            <TButtonSave
              v-if="isDescriptionChange"
              :options="{ disabled: loading }"
              class="ml-1 btn-outline-info"
              variant="outline"
              @click="updateProduct('description', content)"
            />
            <TButtonClose
              v-if="isDescriptionChange"
              variant="outline"
              class="ml-1 btn-outline-danger"
              @click="isDescriptionChange = !isDescriptionChange"
            />
          </div>
        </div>
        <quillEditor
          v-model="content"
          class="editor"
          :disabled="!isDescriptionChange"
        >
        </quillEditor>
      </CCol>
    </CRow>
    <ChangeImageProduct
      :show.sync="primaryImgModal"
      :productId="id"
      :images="product.images"
    />
    <EditAlbumProduct
      :show.sync="albumModal"
      :productId="id"
      :images="product.images"
    />
  </CCard>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import { mapGetters } from "vuex";
import AddProductCategory from "./AddProductCategory.vue";
import AddProductTag from "./AddProductTag.vue";
import ChangeImageProduct from "./ChangeImageProduct.vue";
import EditAlbumProduct from "./EditAlbumProduct.vue";
export default {
  components: {
    ChangeImageProduct,
    EditAlbumProduct,
    AddProductCategory,
    AddProductTag,
    quillEditor,
  },
  data() {
    return {
      detail: [
        { key: "hs_code", label: "Customs clearance code" },
        { key: "name", label: "Product name" },
        { key: "code", label: "Product code" },
        { key: "brand", label: "Brand" },
        { key: "link_store", label: "Link store" },
        { key: "ecommerce_channel_id", label: "Ecommerce channel" },
        { key: "manufacturing_year", label: "Manufacturing year" },
        { key: "initial_price", label: "Regular price" },
        { key: "price", label: "Sale price" },
        { key: "discount", label: "Percentage off" },
        { key: "origin_id", label: "Origin" },
        { key: "tax_percent", label: "Tax percent" },
        { key: "ingredients", label: "Ingredients" },
        { key: "updated_at", label: "Updated at" },
      ],
      embargo: [
        { key: "special", label: "Special goods" },
        { key: "private_license", label: "Private license" },
        { key: "banned_export", label: "Banned export" },
        { key: "banned_sea", label: "Banned sea" },
        { key: "banned_air", label: "Banned air" },
      ],
      specification: [
        { key: "weight", label: "Weight" },
        { key: "height", label: "Height" },
        { key: "width", label: "Width" },
        { key: "length", label: "Length" },
        { key: "quantity_items", label: "Items in box" },
      ],
      primaryImgModal: false,
      albumModal: false,
      showModalAddCategory: false,
      showModalAddTag: false,
      isDescriptionChange: false,
      content: "",
    };
  },
  watch: {
    "product.description": {
      immediate: true,
      handler(v) {
        this.content = v;
      },
    },
  },
  computed: {
    ...mapGetters({
      product: "product.products.detail",
      loading: "product.products.detail.loading",
    }),
    id() {
      return this.$route.params.id;
    },
    imageList() {
      return this.product?.images?.filter((x) => !x.primary) || [];
    },
  },
  methods: {
    updateProduct(field, value) {
      return this.$store.dispatch("product.products.detail.update", {
        [field]: value,
      });
    },
  },
};
</script>
